import React from 'react'
import styled from 'styled-components'
import PostListings from '../components/blog/Listings'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'

const TagHeader = styled.div`
  padding: 2rem 0;
  max-width: ${({ theme }) => theme.maxwidth};
  margin: 0 auto;
`

const TagDisplay = ({ tag }) => (
  <TagHeader>
    <span>posts tagged as </span>
    <h1>{`#${tag}`}</h1>
  </TagHeader>
)

const Container = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.maxwidth};
  padding: 0 1rem;
`

const Tag = ({
  data: {
    blogPosts,
    site: {
      siteMetadata: { title },
    },
  },
  pathContext: { tag },
}) => (
  <div>
    <Layout>
      <SEO
        title={`${tag} - ${title}`}
        description={`Blogposts tagged with #${tag}`}
      />
      <Container>
        <TagDisplay tag={tag} />
        <PostListings {...{ blogPosts }} />
        <Footer />
      </Container>
    </Layout>
  </div>
)

export default Tag

export const query = graphql`
  query TagPageQuery($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    blogPosts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { draft: { eq: false } }
        frontmatter: { tags: { in: [$tag] } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            banner {
              src {
                childImageSharp {
                  fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid(maxWidth: 1024, maxHeight: 550) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              unsplash {
                alt
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
